/**
 * Synapse - adaptive theme
 */


/** Header */

header {
    background: #f3f3f3;
}

/*
@import "header/header-full";
@import "header/menu-main";
@import "header/menu-small";*/
@import "header/togglers";
