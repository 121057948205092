/**
 * Header Togglers.
 */


/** Toggler */

.nav-togglers {
  padding-top: 0px;
}
.nav-toggler {
    position: relative;
    width: 44px;
    height: 40px;
    padding: 0;
    overflow: hidden;
    border-radius: 4px;
    border: 2px solid #0188ca;
    margin-left: 6px;
    color: #0188ca;
    background: none;
}
.nav-toggler:focus {
    outline: none;
}
.nav-toggler.collapsed {
    color: #444;
    border-color: #666;
}
.nav-toggler .icon-bar+.icon-bar {
    margin-top: 0;
}
.nav-toggler .icon-bar {
    position: absolute;
    height: 3px;
    transition: all 0.5s;
    background-color: #0188ca;
    display: block;
    border-radius: 1px;
}
.nav-toggler.collapsed .icon-bar {
    background-color: #666;
}
.nav-toggler .icon-bar-top {
    transform: rotate(-45deg);
}
.nav-toggler .icon-bar-bottom {
    transform: rotate(45deg);
}

/** Menu */

.nav-menu {}
.nav-menu .icon-bar {
    left: 5px;
    top: 17px;
    width: 30px;
}
.nav-menu .icon-bar-middle {
    opacity: 0;
    left: -30px;
    top: 17px;
}
.nav-menu.collapsed .icon-bar-middle {
    opacity: 1;
    left: 5px;
}
.nav-menu.collapsed .icon-bar-top {
    transform: rotate(0);
    top: 8px;
}
.nav-menu.collapsed .icon-bar-bottom {
    transform: rotate(0);
    top: 26px;
}

/** Info */

.nav-info {
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 20px;
}
.nav-info.collapsed {
    width: 41px;
}
.nav-info .icon-bar {
    left: 3px;
    top: 17px;
    width: 30px;
}
.nav-info.collapsed .icon-bar-top {
    transform: rotate(90deg);
    top: 8px;
    width: 3px;
    left: 17px;
}
.nav-info.collapsed .icon-bar-bottom {
    transform: rotate(90deg);
    top: 20px;
    width: 15px;
    left: 11px;
}
